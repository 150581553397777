import API from "./apiConfig"; // Importiere die zentrale API-Instanz

/** Holt alle Unternehmen des Business-Owners */
export const getUserBusinesses = async () => {
  const token = localStorage.getItem("token");
  if (!token) return [];

  const response = await API.get("/api/business-owner/companies", {
    headers: { Authorization: `Bearer ${token}` }, // Fix für Token-Format
  });
  return response.data;
};

/** Fügt ein neues Unternehmen hinzu */
export const addBusiness = async (businessData) => {
  const token = localStorage.getItem("token");
  if (!token) throw new Error("Kein Token vorhanden!");

  const response = await API.post("/api/business-owner/companies", businessData, {
    headers: { Authorization: `Bearer ${token}` }, // Fix für Token-Format
  });
  return response.data;
};
