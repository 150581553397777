import React, { useState } from "react";
import { registerUser } from "../api/auth";

const RegisterPage = () => {
  const [formData, setFormData] = useState({ name: "", email: "", password: "" });

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerUser(formData);
      alert("Registrierung erfolgreich! Du kannst dich jetzt einloggen.");
      window.location.href = "/login";
    } catch (error) {
      alert("Fehler bei der Registrierung.");
    }
  };

  return (
    <div>
      <h2>Registriere dein Geschäft</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="name" placeholder="Geschäftsname" onChange={handleChange} required />
        <input type="email" name="email" placeholder="E-Mail" onChange={handleChange} required />
        <input type="password" name="password" placeholder="Passwort" onChange={handleChange} required />
        <button type="submit">Registrieren</button>
      </form>
    </div>
  );
};

export default RegisterPage;
