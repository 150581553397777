import API from "./apiConfig"; // Importiere die zentrale API-Instanz

/** Login-UserX */
export const loginUser = async (credentials) => {
  const response = await API.post("/api/auth/login", credentials);
  return response.data;
};

/** Registrierung für UserX */
export const registerUser = async (data) => {
  const response = await API.post("/api/auth/register", data);
  return response.data;
};

/** Authentifizierungs-Check */
export const checkAuth = async () => {
  const token = localStorage.getItem("token");
  if (!token) throw new Error("Kein Token vorhanden!");

  const response = await API.get("/api/auth/check", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};
