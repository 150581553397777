import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser, checkAuth } from "../api/auth";
import "../styles/pages/LoginPage.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Überprüfe, ob der Benutzer bereits eingeloggt ist
  useEffect(() => {
    const verifyUser = async () => {
      const user = await checkAuth();
      if (user) {
        navigate("/dashboard"); // Falls eingeloggt, leite zum Dashboard weiter
      }
    };
    verifyUser();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Eingegebenes Passwort:", password); // Debugging
  
    try {
      const response = await loginUser({ email, password });
      console.log("🔑 Server-Antwort:", response);
      localStorage.setItem("token", response.token);
      navigate("/dashboard"); // Erfolgreiches Login -> Weiterleitung
    } catch (err) {
      console.error("🚫 Fehler beim Login:", err);
      setError(err.message || "Login fehlgeschlagen. Bitte überprüfe deine Eingaben.");
    }
  };

  return (
    <div className="login-container">
      <h2>UserX Login</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="E-Mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Passwort"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
