import React, { useEffect, useState } from "react";
import { getUserBusinesses } from "../api/business";
import { useNavigate } from "react-router-dom";

const DashboardHome = ({ user }) => {
  const [businesses, setBusinesses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getUserBusinesses()
      .then((res) => setBusinesses(res.data))
      .catch(() => setBusinesses([]));
  }, []);

  return (
    <div className="dashboard-container">
      <h1>Willkommen, {user.email}</h1>
      <button onClick={() => navigate("/add-business")}>+ Neues Geschäft</button>

      <h2>Meine Geschäfte</h2>
      <ul>
        {businesses.length > 0 ? (
          businesses.map((b) => (
            <li key={b._id}>
              {b.name} - {b.category}
              <button onClick={() => navigate(`/edit-business/${b._id}`)}>Bearbeiten</button>
            </li>
          ))
        ) : (
          <p>Keine Geschäfte gefunden.</p>
        )}
      </ul>
    </div>
  );
};

export default DashboardHome;
